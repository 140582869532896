// variable merging
@import './0_config/_00-variables-default';
@import '~framework/assets/scss/V2/0_config/_00-util-bootstrap';
@import '~framework/assets/scss/V2/0_config/_10-variables-default';
@import '~framework/assets/scss/V2/0_config/_20-variables-bootstrap';
@import '~framework/assets/scss/V2/0_config/_30-variables-override';
@import './0_config/_10-variables-override';

// vendor: bootstrap
@import '~framework/assets/scss/V2/1_vendor/bootstrap';

/*
// OR select manually:

@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
//@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
//@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
//@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
//@import "~bootstrap/scss/badge";
//@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
//@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
//@import "~bootstrap/scss/tooltip";
//@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";
*/
;

// force html font size (prevent tommybooking conflict)
html {
	scroll-behavior: smooth;
	@import '~framework/assets/scss/V2/5_utilities/reset';
}

// theme within body class
body.default {
	//@import '~framework/assets/scss/V2/2_layout/header';
	@import '~framework/assets/scss/V2/2_layout/containers';

	@import '~framework/assets/scss/V2/3_components/alert';
	@import '~framework/assets/scss/V2/3_components/buttons';
	@import '~framework/assets/scss/V2/3_components/cookie-consent';
	@import '~framework/assets/scss/V2/3_components/lists';
	//@import '~framework/assets/scss/V2/3_components/cards';
	@import '~framework/assets/scss/V2/3_components/faq';
	@import '~framework/assets/scss/V2/3_components/form';
	@import '~framework/assets/scss/V2/3_components/recaptcha';
	@import '~framework/assets/scss/V2/3_components/loading';
	@import '~framework/assets/scss/V2/3_components/carousel';
	@import '~framework/assets/scss/V2/3_components/timeslot';

	@import '~framework/assets/scss/V2/4_page_block/gallery';
	@import '~framework/assets/scss/V2/4_page_block/collections';
	//@import '~framework/assets/scss/V2/4_page_block/logo';
	@import '~framework/assets/scss/V2/4_page_block/navbar-toggler';
	@import '~framework/assets/scss/V2/4_page_block/site-switcher';
	//@import '~framework/assets/scss/V2/4_page_block/menu';
	@import '~framework/assets/scss/V2/4_page_block/blog-filter';
	@import '~framework/assets/scss/V2/4_page_block/link';

	@import '~framework/assets/scss/V2/5_utilities/additions';
	@import '~framework/assets/scss/V2/5_utilities/animations';
	//@import '~framework/assets/scss/V2/5_utilities/darkmode';
	//@import '~framework/assets/scss/V2/5_utilities/sticky';

	// app styling
	@import './2_layout/base';
	@import './2_layout/footer';
	@import './2_layout/header';
	@import './2_layout/sections';

	@import './3_components/buttons';
	@import './3_components/cards';
	@import './3_components/lists';

	@import './4_page_block/collections';

	@import '~project/assets/_themes/default/5_utilities/darkmode';
	@import '~project/assets/_themes/default/5_utilities/animations';
}
