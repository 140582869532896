.footer {
	padding: 5vh 0;
	background: $white;

	// footer-contact
	.footer-contact {
		font-size: 18px;
		.container-two-columns {
			.container-holder {
				@include media-breakpoint-up(md) {
					justify-content: space-between;
				}
				.column {
					@include media-breakpoint-up(md) {
						@include make-col-auto();
					}
					&.one {
						flex-grow: 1;
						flex-flow: row wrap;
						.footer-text {
							@include media-breakpoint-up(md) {
								min-width: 25%;
							}
							padding-right: 30px;
						}
					}
				}
			}
		}
	}

	// footer-socket
	.footer-socket {
		padding-top: 3vh;
		font-size: 14px;
		.footer-link {
			.list {
				.list-item {
					margin-right: 0;
					@include media-breakpoint-up(lg) {
						+ li::before {
							content: "/";
							margin: 0 10px;
						}
					}
				}
			}
		}
	}
}
