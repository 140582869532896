// body
& {
	background: url("/images/body-bg.svg") left 55px $body-bg;
	background-attachment: fixed;
	font-size: $font-size-lg;
	.form-control {
		font-size: $font-size-lg !important;
	}
	@include media-breakpoint-down(lg) {
		font-size: $font-size-sm;
		background-size: 50%;
		.form-control {
			font-size: $font-size-sm !important;
		}
	}
}

// headings
h5,
h6 {
	font-weight: 700;
}
strong {
	font-weight: 800;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}
iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s !important;
}

// form
.form {
	padding: 15px;
	@include media-breakpoint-up(xl) {
		padding: 30px;
	}
	border: 1px solid #ced4da;
	border-radius: 10px;
}

// social-share
.social-share {
	background: $primary;
	padding: 15px;
}
