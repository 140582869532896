.btn {
	padding: 13px 25px;
	border-radius: 6px;
	font-size: $font-size-lg;
	@include media-breakpoint-down(lg) {
		font-size: $font-size-sm;
	}
	font-weight: 700;
	line-height: 20px;

	// btn-primary
	&.btn-primary {
		background: $tertiary;
		border-color: $tertiary;
		color: $white;
		&:hover {
			background: lighten($tertiary, 7.5%);
			border-color: lighten($tertiary, 7.5%);
			color: $white;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		background: transparent;
		border-color: $tertiary;
		color: $tertiary;
		&:hover {
			background: $tertiary;
			border-color: $tertiary;
			color: $white;
		}
	}

	// btn-secondary
	&.btn-secondary {
		background: $secondary;
		border-color: $secondary;
		color: $white;
		&:hover {
			background: lighten($secondary, 7.5%);
			border-color: lighten($secondary, 7.5%);
			color: $white;
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		background: transparent;
		border-color: $secondary;
		color: $secondary;
		&:hover {
			background: $secondary;
			border-color: $secondary;
			color: $white;
		}
	}
}

// btn-back
// =========================================================================
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;
	color: $primary-light;
	font-size: 18px;
	font-weight: 800;
	text-decoration: underline;
	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
	}
}

// btn-down
// =========================================================================
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
