// override variables after vendors like bootstrap are loaded

// colors
$pink: #c51c6d;
$blue: #1c4662;
$blue-light: #009dc8;
$green: #8ba41b;
$green-light: #eceee4;

// theme-colors
$primary: $blue;
$primary-light: $blue-light;
$secondary: $green;
$secondary-light: $green-light;
$tertiary: $pink;

$dark: $primary;

// body
$body-bg: $secondary-light;
$body-color: $primary;

// links
$link-color: $primary-light;

// typography
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
$font-family-primary: 'Outfit', sans-serif;

$font-awesome: 'Font Awesome 5 Pro';
$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px
$font-size-sm: $font-size-base * 1.125; // 18px
$font-size-lg: $font-size-base * 1.5; // 24px

$font-weight-base: 300;
$line-height-base: 1.25;

$h1-font-size: $font-size-base * 5; // 80px
$h2-font-size: $font-size-base * 3.75; // 60px
$h3-font-size: $font-size-base * 2.25; // 36px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.25; // 20px
$h6-font-size: $font-size-base * 1.125; // 18px

$headings-font-family: $font-family-base;
$headings-font-weight: 800;
$headings-line-height: 1;
$headings-color: $primary;
