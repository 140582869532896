.header-top {
	max-width: 100%;
	overflow: hidden;
}

div.usps {
	white-space: nowrap;
	overflow: hidden;
	display: inline-block;
	animation: marquee 20s linear infinite;
}

div.usps ul.usps {
	display: inline-block;
}

@keyframes marquee {
	0% {
		transform: translate3d(0, 0, 0);
	}
	100% {
		transform: translate3d(-50%, 0, 0);
	}
}

.usps {
	display: inline-block !important;
}

// slider zoom


.owl-stage .owl-item {
	overflow: hidden;
}

.owl-stage-outer .item {
	background-size: cover;
}

.banner-section > .carousel.animation .item {
	transition-delay: 100ms;
	-webkit-animation: zoomin 2.5s 1;
	animation: zoomin 2.5s 1;
}

.fadetext-section:not(.animation) {
	.column {
		display: none !important;
	}
}

.fadetext-section.animation .column {
	-webkit-animation: opacity .5s;
	animation: opacity .5s;
}

@-webkit-keyframes zoomin {
	0% {
		-webkit-transform: scale(2);
	}
	100% {
		-webkit-transform: scale(1);
	}
}
@keyframes zoomin {
	0% {
		-webkit-transform: scale(2);
	}
	100% {
		-webkit-transform: scale(1);
	}
}

@-webkit-keyframes opacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes opacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}


&.home .lead-section {
	min-height: calc(100vh - 373px);
	align-items: flex-start;
	display: flex;
}

&.home .banner-section {
	min-height: 65vh !important;

	.item {
		height: 65vh !important;
		min-height: 65vh !important;
		max-height: 65vh !important;
	}
}

.fullheight-section {
	min-height: calc(100vh - 118px);
	display: flex;
	align-items: center;
}

&.home .news-section {
	min-height: calc(35vh - 125px) !important;
}

&.home .outro-section {
	min-height: calc(100vh - 381px);
}


@-webkit-keyframes fade-in-top {
	0% {
		transform: translateY(-50%);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes fade-in-top {
	0% {
		transform: translateY(-50%);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@-webkit-keyframes fade-in-right {
	0% {
		transform: translateX(50%);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes fade-in-right {
	0% {
		transform: translateX(50%);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}


&.home .lead-section {
	.page-block.title {
		> h1 {
			-webkit-animation: fade-in-top 1.5s;
			animation: fade-in-top 1.5s;
		}
	}

	.page-block.wysiwyg {
		-webkit-animation: fade-in-right 1.5s;
		animation: fade-in-right 1.5s;
	}
}
