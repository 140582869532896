.collection {
	// grid
	&.grid {
		.grid-items {
			.item {
				@include media-breakpoint-up(xxl) {
					@include make-col(4);
				}
			}
		}
	}
	// blog-overview
	&.blog-overview {
		.card {
			.card-subtitle {
				@extend .d-none;
			}
		}
	}
}
