// custom-list
ul.custom-list {
    @extend .list-unstyled;
	margin-left: 30px;
	@include media-breakpoint-up(xl) {
		margin-left: 50px;
	}
    li {
        position: relative;
		margin: 5px 0;
        padding: 0 0 0 30px;
		color: $primary;
		font-weight: 600;
        &::before {
            content: '//';
            position: absolute;
            top: 0;
            left: 0;
            color: $tertiary;
            font-weight: 900;
        }
		sup {
			display: block;
			top: 0;
			margin-top: 5px;
			font-weight: 400;
			line-height: 1.2;
		}
    }
}

// custom-list-extra
ul.custom-list-extra {
    @extend .list-unstyled;
    li {
        position: relative;
        padding: 0 0 0 30px;
        &::before {
            content: '\f058';
            position: absolute;
            top: 0;
            left: 0;
			font-family: $font-awesome;
            color: $primary;
            font-weight: 400;
        }
    }
}
