.bg-dark {
	background-color: $dark !important;

	&,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $body-color--dark;
	}

	// list
	ul,
	ol {
		li {
			color: $body-color--dark;
		}
	}

	// custom-list
	ul.custom-list,
	ul.custom-list-extra {
		li::before {
			color: $body-color--dark;
		}
	}

	// alert
	.alert-danger {
		background: transparent;
	}

	// form
	.form {
		color: $primary;
		background: $secondary-light;
		border: none;
		.form-control {
			border-color: #707070;
		}
		.form-control-label {
			font-weight: 500;
		}
		.form-check-label,
		small {
			font-size: 18px;
		}
	}

	// owl-carousel
	.owl-carousel {
		&.slider {
			.owl-nav {
				.owl-prev,
				.owl-next {
					color: $body-color--dark;
					&:hover {
						color: darken($body-color--dark, 50%);
					}
				}
			}
			.owl-dots {
				.owl-dot {
					span {
						background: transparent;
						border-color: $body-color--dark;
					}
					&:hover {
						span {
							background: $body-color--dark;
							border-color: $body-color--dark;
						}
					}
					&.active {
						span {
							background: $body-color--dark;
							border-color:$body-color--dark;
						}
					}
				}
			}
		}
	}

	// card
	.card {
		color: $body-color;
		.card-body {
			.card-caption {
				.card-title-link {
					.card-title {
						color: $body-color;
					}
				}
				.card-subtitle {
					color: $body-color;
				}
			}
		}
	}


}

// card ?
.card.dark {
	background: $card-bg--dark;
	border: $card-border-width solid transparent;

	.card-image {
		background: $dark;
	}

	.card-body {
		background: $card-bg--dark;
	}

	.card-caption {
		.card-title {
			color: $white;
		}

		.card-title-link {
			color: $white;

			.card-title {
				margin: 0;
				color: inherit;
			}

			&:hover {
				color: $primary;
			}
		}

		.card-subtitle {
			color: $white;
		}

		.card-description {
			color: $white;

			&::after {
				background: $card-bg--dark;
				background: $card-bg-fade--dark;
			}
		}

		.card-prices,
		.card-price {
			color: $white;
		}
	}

	.card-info {
		.card-prices {
			.card-price-label,
			.card-old-price {
				color: $white;
			}

			.card-price {
				color: $primary;
			}
		}

		.card-traveldata {
			.card-arrival-label,
			.card-arrival-data,
			.card-duration-data {
				color: $white;
			}
		}
	}

	.card-btn {
		background: $white;
		border: $btn-border-width solid $white;

		color: $body-color;

		&:hover {
			background: $primary;
			border: $btn-border-width solid $primary;

			color: $white;
		}
	}
}
