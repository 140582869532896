.header {
	// header-top
	.header-top {
		margin-bottom: 60px;
		@include media-breakpoint-down(lg) {
			margin-bottom: 30px;
		}
		background: $primary;
		color: $white;
		font-weight: 600;
		overflow: hidden;

		// usps
		div.usps {
			ul {
				flex-wrap: nowrap !important;
				align-items: center;
				height: 60px;
				overflow-y: hidden;
				overflow-x: scroll;

				&::-webkit-scrollbar {
					display: none;
				}

				-ms-overflow-style: none;
				scrollbar-width: none;
				margin-bottom: 0;
				white-space: nowrap;
				font-size: 16px;
				line-height: 60px;
				@include media-breakpoint-down(lg) {
					height: 45px;
					font-size: 14px;
					line-height: 45px;
				}

				li {
					display: inline-block;
					position: relative;
					margin: 0 15px;
					padding-left: 54px;

					&::before {
						content: "";
						position: absolute;
						top: 18px;
						left: 0;
						width: 39px;
						height: 24px;
						background: url("/images/visual-bird-white.svg");
						@include media-breakpoint-down(lg) {
							top: 13px;
							width: 31px;
							height: 19px;
							background-size: cover;
						}
					}
				}
			}
		}
	}

	// header-main
	.header-main {
		.container {
			.container-holder {
				.column {
					flex-flow: row wrap;
				}
			}
		}

		// menu-toggle
		.menu-toggle {
			margin-right: auto;
			@include media-breakpoint-up(xl) {
				display: none;
			}

			.navbar-toggler {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 36px;
				height: 36px;
				padding: 0;
				border: none;
				color: $tertiary;
				font-size: 24px;
				transition: 0.5s;
				text-align: center;

				&[aria-expanded="true"] {
					i {
						&::before {
							content: "\f00d";
						}
					}
				}
			}
		}

		// logo
		.logo {
			@include media-breakpoint-down(lg) {
				order: 3;
				width: 100%;
				margin: 15px 0;
			}

			img {
				transition: 0.5s;
			}

			a {
				@extend .d-block;
				width: 156px;
				transition: 0.5s;
			}
		}

		// menu
		.navbar-collapse {
			align-items: flex-start;
		}

		.menu {
			@include media-breakpoint-down(lg) {
				order: 4;
				.navbar-nav {
					.nav-item {
						font-weight: 500;

						.nav-link {
							padding: 0.5rem 0;
							color: $tertiary;

							&:hover {
								color: $secondary;
							}
						}

						&.active {
							> .nav-link {
								color: $primary;
							}
						}

						.dropdown-menu {
							position: static !important;
							transform: none !important;
							margin: 0 0 0 15px;
							padding: 0;
							border: none;
							background-color: transparent;
						}
					}
				}
			}
			@include media-breakpoint-up(xl) {
				display: flex;
				flex-basis: auto;
				.navbar-nav {
					flex-direction: row;
					margin-left: auto;

					.nav-item {
						margin: 0 15px;
						font-size: 20px;
						font-weight: 700;
						line-height: 36px;

						.nav-link {
							padding: 0;
							color: $tertiary;

							&:hover {
								color: $primary-light;
							}
						}

						&.active {
							.nav-link {
								color: $primary-light;
							}
						}

						.dropdown-menu {
							background: none;
							border: none;
							border-radius: 0;
							padding: 0;

							.nav-item {
								margin: 0;
								font-size: 18px;
								font-weight: 400;
								line-height: normal;
								white-space: nowrap;

								.nav-link {
									padding: 2px 0;
									color: $tertiary;

									&:hover {
										color: $primary;
									}
								}

								&.active {
									> .nav-link {
										color: $primary;
									}
								}
							}
						}
					}
				}
			}
		}

		// social
		div.social {
			margin-left: 15px;

			ul {
				margin-bottom: 0;

				li {
					margin-right: 0;
					font-size: 24px;
					line-height: 36px;

					+ li {
						margin-left: 10px;
					}

					a {
						display: block;
						color: $primary;

						span {
							display: none;
						}

						&:hover {
							color: $tertiary;
						}
					}
				}
			}
		}
	}
}

&.header-navbar-active {
	.header-main {
		.logo {
			a {
				@include media-breakpoint-down(lg) {
					width: 36px;
				}
			}
		}
	}
}

&.sticky-header {
	.header .header-top {
		margin-bottom: 215px;
	}

	.header-main {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		background: #fff;
		z-index: 90000;
		box-shadow: 0 0 13px #00000040;

		div.social ul li a {
			color: #1c4662 !important;
		}

		.menu {
			align-items: center;

			a {
				span,
				&:after {
					color: #1c4662;
				}
			}

			.dropdown-menu {
				padding: 5px !important;
				background: #fff !important;
			}
		}

		.logo img {
			transform: scale(0.6);
		}

		.social li {
			display: flex;
			align-items: center;
		}
	}
}
