// card
.card {
	@extend .h-100;
	background: $secondary-light;

	.card-image {
		@extend .position-relative;
		flex: 0 0 auto;
		overflow: hidden;

		.card-img {
			@extend .d-block;
			border-radius: 0;
			transition: 0.5s;
		}

		.card-image-caption {
			@extend .position-absolute;
			top: auto;
			right: 0;
			bottom: 0;
			left: 0;

			display: block;
			align-items: stretch;
			justify-content: flex-start;
			padding: 0;
			background: transparent;

			.card-image-label {
				@extend .text-truncate;
			}
		}
	}

	.card-body {
		@extend .d-flex;
		@extend .flex-column;
	}

	.card-caption {
		flex: 1 1 auto;

		.card-title-link {
			text-decoration: none;

			> h3 {
				font-size: 1.5rem;
			}
		}

		.card-subtitle {
			margin: 15px 0;
			color: $white;
			font-weight: 300;
			font-size: $font-size-sm;
			@include media-breakpoint-up(xl) {
				font-size: $font-size-lg;
			}
		}

		.card-description {
			.card-description-content {
				font-size: 1.125rem;
			}
		}
	}

	.card-buttons {
		margin-top: 15px;

		.card-btn {
			font-weight: 800;
			text-transform: lowercase;
		}
	}

	&:hover {
		.card-image {
			.card-img {
				transform: scale(1.03);
			}
		}
	}
}

// card > card-overlay
.card.card-overlay {
}

// card > card-assortiment
.card.card-assortiment {
	.card-caption {
		.card-prices {
			.card-price {
				@extend .text-truncate;
			}

			.card-price-message {
				@extend .text-truncate;
			}
		}
	}
}

// card > card-arrangement
.card.card-arrangement {
	.card-info {
		@extend .d-flex;
		@extend .flex-wrap;
		flex: 0 0 auto;
	}

	.card-prices {
		@include make-col(12);
		@include media-breakpoint-up(sm) {
			@include make-col(6);
		}

		.card-price-label {
			font-weight: $font-weight-bold;
		}
	}

	.card-traveldata {
		@include make-col(12);
		@extend .mt-3;
		@extend .mt-sm-0;
		@include media-breakpoint-up(sm) {
			@include make-col(6);
		}

		.card-arrival-label {
			font-weight: $font-weight-bold;
		}
	}

	.card-characteristics {
		margin: 0;
	}

	.card-buttons {
		margin: 0;
	}
}

// card > card-wide
.card.card-wide {
	@extend .flex-row;
	@extend .flex-wrap;

	.card-image {
		@include make-col(12);
		@include media-breakpoint-up(sm) {
			@include make-col(12);
		}
		@include media-breakpoint-up(md) {
			@include make-col(5);
		}
		@include media-breakpoint-up(lg) {
			@include make-col(4);
		}
		@include media-breakpoint-up(xl) {
			@include make-col(4);
		}
	}

	.card-body {
		@include make-col(12);
		@include media-breakpoint-up(sm) {
			@include make-col(12);
		}
		@include media-breakpoint-up(md) {
			@include make-col(7);
		}
		@include media-breakpoint-up(lg) {
			@include make-col(8);
		}
		@include media-breakpoint-up(xl) {
			@include make-col(8);
		}
	}
}
