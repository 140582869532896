// bg-dark
// =========================================================================
section {
	&.bg-dark {
		margin: 0;
		padding: 8vh 0;

		&.bg-green {
			background: $secondary !important;

			a {
				color: $white;

				&:hover {
					color: $tertiary;
				}
			}
		}
	}
}

// lead-section
// =========================================================================
.lead-section {
	margin: 4vh 0 8vh 0;

	*:not(a) {
		color: $tertiary;
	}

	.container-one-column {
		.container-holder {
			@include media-breakpoint-up(xl) {
				h1 {
					max-width: 1000px;
				}
				p {
					max-width: 800px;
				}
			}
		}
	}
}

&.employee-employee-overview {
	.lead-section {
		margin-bottom: 30px;
		// employee-filter
		.employee-filter {
			margin: auto 0 0 35px;

			ul {
				display: flex;
				flex-wrap: wrap;
				margin: 30px 0 0 0;
				padding: 0;
				list-style: none;

				li {
					display: flex;
					align-items: center;
					color: $primary;
					font-size: 18px;
					font-weight: 800;

					+ li {
						&::before {
							content: "/";
							margin: 0 5px;
							font-size: 20px;
						}
					}
				}
			}
		}
	}
}

&.blog-post-detail {
	.lead-section {
		.info {
			.subtitle {
				@extend .d-none;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;

	// two-columns
	.container-two-columns {
		margin-top: 6vh;

		&:first-of-type {
			margin-top: 0;
		}

		.container-holder {
			padding-left: 30px;
			@include media-breakpoint-up(xl) {
				max-width: 1090px;
				padding-left: 35px;
			}

			.column.one {
				@include media-breakpoint-up(md) {
					@include make-col(4);
				}
				padding-right: 15px !important;
				word-break: break-word;
			}

			.column.two {
				@include media-breakpoint-up(md) {
					@include make-col(8);
				}
			}
		}
	}
}

// news-section
// =========================================================================
.news-section {
	@extend .my-0;
	@extend .py-0;
	// collection
	.collection,
	.wysiwyg {
		z-index: 100;
		max-width: 700px;
		margin-top: -300px;
		padding: 50px 50px 8vh 50px;
		@include media-breakpoint-down(lg) {
			margin-top: -100px;
			padding: 30px 30px 8vh 30px;
		}
		background: $secondary;

		&.grid {
			.grid-items {
				.item {
					margin-bottom: 5vh;
					@include media-breakpoint-up(sm) {
						@include make-col(12);
					}
					@include media-breakpoint-up(md) {
						@include make-col(12);
					}
					@include media-breakpoint-up(lg) {
						@include make-col(12);
					}
					@include media-breakpoint-up(xxl) {
						@include make-col(12);
					}

					.card {
						padding-bottom: 5vh;
						background: none;
						border-radius: 0;
						border: none;
						border-bottom: 1px solid $white;

						.card-image {
							display: none;
						}

						.card-body {
							padding: 0;
							color: $white;

							.card-caption {
								.card-title-link {
									.card-title {
										color: $white;
									}
								}

								.card-subtitle {
									@extend .d-none;
								}
							}
						}
					}

					&:last-of-type {
						margin-bottom: 30px;

						.card {
							border-bottom: none;
							padding-bottom: 0;
						}
					}
				}
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
	@extend .my-0;
	@extend .py-0;

	// owl-carousel
	.owl-carousel {
		height: 50vh;
		min-height: 300px;
		max-height: 540px;

		.item {
			height: 50vh !important; /* overide */
			min-height: 300px;
			max-height: 540px;
			padding: unset;
		}

		.owl-dots,
		.owl-nav {
			display: none;
		}
	}

	// wysiwyg
	.wysiwyg {
		z-index: 100;
		max-width: 700px;
		margin-top: -150px;
		padding: 50px 50px 8vh 50px;
		@include media-breakpoint-down(lg) {
			margin-top: -100px;
			padding: 30px 30px 8vh 30px;
		}
		background: $secondary;
	}
}

// outro-section
// =========================================================================
.outro-section {
	padding: 8vh 0;
	background: transparent;

	*:not(a) {
		color: $tertiary;
	}

	// one-column
	.container-one-column {
		.container-holder {
			padding-left: 30px;
			@include media-breakpoint-up(xl) {
				max-width: 1090px;
				padding-left: 50px;
				p {
					max-width: 800px;
				}
			}
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// one-column
	.container-one-column {
		padding-left: 30px;

		.container-holder {
			@include media-breakpoint-up(xl) {
				max-width: 1090px;
				padding-left: 35px;
			}
		}
	}

	// two-columns
	.container-two-columns {
		&:not(:nth-child(1)) {
			margin-top: 6vh;
		}

		.container-holder {
			padding-left: 30px;
			@include media-breakpoint-up(xl) {
				max-width: 1090px;
				padding-left: 35px;
			}

			.column.one {
				@include media-breakpoint-up(md) {
					@include make-col(4);
				}
				padding-right: 15px !important;
				word-break: break-word;
			}

			.column.two {
				@include media-breakpoint-up(md) {
					@include make-col(8);
				}
			}
		}
	}

	// employee-overview
	.employee-overview {
		padding-bottom: 6vh;
		border-bottom: 1px solid $white;

		&.grid {
			.grid-items {
				@extend .justify-content-start;

				.item {
					@include make-col(6);

					.card {
						background: none;
						border: none;
						@extend .flex-row;
						@extend .flex-wrap;
						@extend .align-items-center;
						color: $white;

						.card-image {
							@include media-breakpoint-down(md) {
								margin-bottom: 15px;
							}
							width: 110px;
							height: 110px;
							margin-right: 20px;
							border-radius: 100%;
							background: rgba(255, 255, 255, 0.1);

							img {
								border-radius: 100%;
							}
						}

						.card-body {
							max-width: 175px;
							padding: 0;
							font-size: 16px;

							.card-title {
								margin-bottom: 0;
								color: $white;
								font-weight: 800;
							}

							.card-text {
								font-weight: 300;

								p {
									margin-bottom: 0;
								}
							}

							.card-buttons {
								display: none;
								margin-top: 0;
							}
						}
					}
				}
			}
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
	@extend .bg-dark;
}
